import { useContext } from 'react'
import { HeaderContext } from '../header'
import CreateIcon from './create-icon'
import MenuIcon from './menu-icon'
import { StyledAvatar, Button, CategoryButton, NavContainer, UserButton } from './sc'
import SearchIcon from './search-icon'
import UserIcon from './user-icon'

const Nav = () => {
    const showSearchButton = false // for when we add Gif Detail which has no search
    const { isSearchVisible, menu, setIsSearchVisible, setMenuState, user } = useContext(HeaderContext)
    const userClick = () => {
        if (user) {
            setMenuState(menu === 'user' ? undefined : 'user')
        } else {
            setMenuState(menu === 'logged out user' ? undefined : 'logged out user')
        }
    }

    return (
        <NavContainer>
            {showSearchButton && (
                <SearchIcon
                    isSearchVisible={isSearchVisible}
                    onClick={() => {
                        setMenuState(undefined)
                        userClick()
                        if (menu) {
                            // just give it a 100ms to remove other menu
                            setTimeout(() => setIsSearchVisible(!isSearchVisible), 100)
                        } else {
                            setIsSearchVisible(!isSearchVisible)
                        }
                    }}
                />
            )}
            <Button href="/create/gifmaker" title="GifMaker">
                <CreateIcon />
            </Button>
            <UserButton
                onClick={() => {
                    setIsSearchVisible(false)
                    setMenuState(undefined)
                    userClick()
                }}
            >
                {user === undefined ? null : user ? (
                    <StyledAvatar user={user} isActive={menu === 'user'} height={40} width={40} />
                ) : (
                    <UserIcon />
                )}
            </UserButton>
            <CategoryButton
                onClick={() => {
                    setIsSearchVisible(false)
                    setMenuState(menu === 'category' ? undefined : 'category')
                }}
            >
                <MenuIcon isActive={menu === 'category'} />
            </CategoryButton>
        </NavContainer>
    )
}

export default Nav
