const Items = {
    'GIPHY Studios': '/giphystudios',
    Animals: '/categories/animals',
    Actions: '/categories/actions',
    Anime: '/categories/anime',
    Cartoons: '/categories/cartoons-comics',
    Emotions: '/categories/emotions',
    'Food/Drink': '/categories/food-drink',
    Gaming: '/gaming',
    'Holidays/Greetings': '/greetings',
    Memes: '/categories/memes',
    Clips: '/clips',
    Originals: '/originals/stickers/studios-stickers',
    Trending: '/stickers/trending',
    Reactions: '/stickers/reactions',
    Packs: '/stickers/packs',
    GIPHY: '/apps/giphy',
    'GIPHY Capture': '/apps/giphycapture',
    Team: '/team',
    Studios: '/studios',
    Developers: 'https://developers.giphy.com/',
    FAQ: '/faq',
    Support: '/support',
    Jobs: 'https://careers.shutterstock.com/us/en/search-results?keywords=giphy',
    DMCA: '/dmca',
    Ads: 'https://ads.giphy.com',
    Transparency: '/transparency/2023',
} as const

export default Items
