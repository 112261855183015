import { giphyBlue, giphyPink } from '@giphy/colors'
import { IUser } from '@giphy/js-types'
import { useContext } from 'react'
import { ILoggedInUser } from 'types'
import { HeaderContext } from '../header'
import MenuComponent from './menu'
import UserMenu from './user-menu'

export type UserMenuItem = {
    path: string
    label: string
    permission?: string
}

export type MainMenuLink = {
    title: string
    link: string
    newPill?: boolean
}
export type MainMenuItem = {
    header: MainMenuLink
    items: (MainMenuLink | string)[]
}

type Props = { onItemClick: (path: string) => void }
const Menu = ({ onItemClick }: Props) => {
    const { menu, user } = useContext(HeaderContext)
    switch (menu) {
        case 'logged out user':
            return <UserMenu color={giphyBlue} items={loggedOutUserItems} itemClick={onItemClick} />
        case 'user':
            return user ? <UserMenu color={giphyBlue} items={userMenuItems(user)} itemClick={onItemClick} /> : null
        case 'category':
            return <MenuComponent color={giphyPink} items={mainMenuItems()} />
        default:
            return null
    }
}

const loggedOutUserItems: UserMenuItem[] = [
    { path: '/login', label: 'Login' },
    { path: '/join', label: 'Join' },
    { path: '/favorites', label: 'Favorites' },
]

const userMenuItems = (user: IUser): UserMenuItem[] => {
    const { channel, permissions } = user as ILoggedInUser
    const items: UserMenuItem[] = [
        {
            path: '/settings',
            label: 'Settings',
        },
        {
            path: channel,
            label: 'Channel',
        },
        {
            path: '/favorites',
            label: 'Favorites',
        },
        {
            path: '/logout',
            label: 'Log Out',
        },
    ]

    const gatedItems = [
        {
            path: '/dashboard',
            label: 'Dashboard',
            permission: 'dashboard_analytics',
        },
    ]

    // @ts-ignore TODO type this
    return [...gatedItems.filter((item) => permissions[item.permission]), ...items]
}

const getItem =
    (prefix: string, joiner = '') =>
    (title: string | object): MainMenuLink | string =>
        title instanceof Object
            ? (title as unknown as string)
            : {
                  title,
                  link: `${prefix}${encodeURIComponent(title.replace(' ', joiner || ''))}`.toLowerCase(),
              }

export const mainMenuItems = (): MainMenuItem[] => [
    {
        header: { title: 'Categories', link: '/categories' },
        items: [
            { title: `GIPHY Studios`, link: `/giphystudios` },
            { title: `Animals`, link: `/categories/animals` },
            { title: `Artists`, link: `/artists` },
            { title: `Emotions`, link: `/categories/emotions` },
            { title: `Entertainment`, link: `/entertainment` },
            { title: `Gaming`, link: `/gaming` },
            { title: `Holidays/Greetings`, link: `/greetings` },
            { title: `Reactions`, link: `/categories/reactions` },
            { title: `Sports`, link: `/sports` },
            { title: `Clips`, link: `/clips` },
        ],
    },
    {
        header: { title: 'Stickers', link: '/stickers' },
        items: [
            {
                title: 'Originals',
                link: '/originals/stickers/studios-stickers',
            },
            'Trending',
            'Reactions',
            'Packs',
        ].map(getItem('/stickers/', '-')),
    },
    {
        header: { title: 'Apps', link: '/apps' },
        items: ['GIPHY', 'GIPHY Keys'].map(getItem('/apps/')),
    },
    {
        header: { title: 'About', link: '/about' },
        items: [
            'DMCA',
            'FAQ',
            {
                title: 'Jobs',
                link: 'https://careers.shutterstock.com/us/en/search-results?keywords=giphy',
            },
            {
                title: 'Community Guidelines',
                link: '/community-guidelines',
            },
        ].map(getItem('/')),
    },
]

export default Menu
